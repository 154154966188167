     <template>
    <span>
        <drop-down tag="div" position="right" class="edit-link">
            <span slot="title" class="">
                <i class="fas fa-cog cursor-pointer"></i>
            </span>

            <a href="/#" class="dropdown-item py-0"
               @click.prevent="showNewRoundForm = true">
                <i class="fas fa-plus"></i> Create New Round</a>

            <a href="#"
               v-if="round.number !== 0"
               class="dropdown-item py-0 text-danger"
               @click.prevent="deleteRound()">
               <i class="fas fa-trash"></i> Delete {{ round.name }}
            </a>
        </drop-down>

        <el-dialog title="Create New Round"
                   :width="dialogWidth + '%'"
                   top="1vh"
                   :close-on-click-modal="false"
                   :visible.sync="showNewRoundForm">
            <form method="POST" enctype="multipart/form-data" class="roster-form">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6>Select Roster</h6>
                                <small>One roster can be used for multiple rounds, or define a new roster for each round.
                                <router-link to="rosters">Manage Rosters</router-link>
                                </small>

                                <el-select v-model="newRoundRosterId" class="w-100 select-primary">
                                    <el-option v-for="roster in rosters" class="select-primary"
                                               :value="roster.id"
                                               :label="roster.name"
                                               :key="roster.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </li>
                </ul>
            </form>
            <div slot="footer">
                <n-button round type="info" @click.native="showNewRoundForm = false">Cancel</n-button>
                <n-button round type="success" @click.native="createRound" :disabled="$store.getters['loading']">Save Roster
                </n-button>
            </div>
        </el-dialog>
    </span>
</template>
<script>
import DropDown from '@/components/stateless/DropDown'
import { Dialog, Option, Select } from 'element-ui'

export default {
  name: 'round-crud',
  components: {
    DropDown,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    round: Object,
    rosters: Array
  },
  data () {
    return {
      showNewRoundForm: false,
      newRoundRosterId: null
    }
  },
  methods: {
    createRound () {
      this.$emit('create-round', { roster_id: this.newRoundRosterId })
      this.showNewRoundForm = false
    },
    deleteRound () {
      this.$emit('delete-round')
    }
  },
  computed: {
    dialogWidth () {
      return this.screenSize !== 'lg' && this.screenSize !== 'xl' ? 90 : 40
    }
  }
}
</script>
<style>
    #round-container .edit-link {
        margin-top: -80px;
        margin-right: -10px;
        /*position: relative;*/
        /*top: -25px;*/
        /*right: 0;*/
        /*float: right;*/
        /*!*margin-top: -50px;*!*/
        /*z-index: 9000;*/
    }
    #round-container .el-tabs__content {
        overflow: visible !important;
    }

</style>
